/**
 * @Author          : SuXiao xiao.su_ext@novartis.com
 * @Date            : 2024-10-09 18:43:02
 * @LastEditors     : SuXiao xiao.su_ext@novartis.com
 * @LastEditTime    : 2025-01-23 14:30:48
 * @FilePath        : \wecom-front-end-admin-portal\src\router\nuoxiaoer.router.ts
 * @Description     : [诺小二AI] 相关路由
 * @
 */
import { Role } from '@/model/schema';

const routes = [

  {
    path: '/documentManagement',
    name: 'nuoxiaoerDocumentList',
    component: () => import('../views/nuoxiaoerAI/documentManagement.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '文档管理',
      titleEn: 'documentManagement'
    }
  },
  {
    path: '/addDocument',
    name: 'nuoxiaoerDocumentEdit',
    component: () => import('../views/nuoxiaoerAI/addDocument.vue'),
    meta: {
      roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
      auth: true,
      title: '文档配置',
      titleEn: 'addDocument'
    }
  },
  {
    path: '/nuoxiaoer-configurations',
    name: 'nuoxiaoerConfigurations',
    component: () => import('../views/nuoxiaoerAI/configurations.vue'),
    meta: {
      roles: [],
      auth: true,
      title: '通用配置项',
      titleEn: 'configurations'
    }
  },
  // {
  //   path: '/nuoxiaoer/level1Tag',
  //   name: 'level1Tag',
  //   component: () => import('../views/nuoxiaoerAI/addDocument.vue'),
  //   meta: {
  //     roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
  //     auth: true,
  //     title: '一级标签管理',
  //     titleEn: 'addDocument'
  //   }
  // },
  // {
  //   path: '/nuoxiaoer/level2Tag',
  //   name: 'level2Tag',
  //   component: () => import('../views/nuoxiaoerAI/addDocument.vue'),
  //   meta: {
  //     roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
  //     auth: true,
  //     title: '二级标签管理',
  //     titleEn: 'addDocument'
  //   }
  // },
  // {
  //   path: '/nuoxiaoer/banner',
  //   name: 'banner',
  //   component: () => import('../views/nuoxiaoerAI/addDocument.vue'),
  //   meta: {
  //     roles: [Role.SUPER_ADMIN, Role.DIGITAL_ADMIN, Role.CHANNEL_ADMIN],
  //     auth: true,
  //     title: 'banner管理',
  //     titleEn: 'addDocument'
  //   }
  // },
]

export default routes;
